import { useEffect, useState } from 'react';
import {Link,useNavigate} from 'react-router-dom';


function Dashboard({loginStatus}){
    
    const navigate=useNavigate();
    const [activeSessionVisibility,setActiveSessionVisibility]=useState('displayNone');

useEffect(()=>{

    if(loginStatus==='Log in'){
        navigate('/login');
    }else{
        setActiveSessionVisibility('displayBlock');
    }

},[]); 



return(
    <div className='H100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'>
    <Link className={`W80vw textAlignCenter readableSymbolSize fontWeightBold backgroundBlack borderRadius1vw minimalPadding minimalMargin textDecorationNone colorWhite ${activeSessionVisibility}`} to='/createpoll'><span className='padding0 readableSymbolSize colorGreen0 minimalPadding'>+ </span>CREATE A POLL</Link>
    <Link className={`W80vw textAlignCenter readableSymbolSize fontWeightBold backgroundBlack borderRadius1vw minimalPadding minimalMargin textDecorationNone colorWhite ${activeSessionVisibility}`} to='/polls'>TRACK YOUR POLLS</Link>
</div>
);
}
export default Dashboard;
//import {useState} from 'react';
//import logo from './logo.svg';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import './App.css';
import {Link, Route, Routes, useNavigate } from 'react-router-dom';
// import ModelViewer from "./ModelObject";
import SignUp from "./SignUp";
import Login from "./Login";
import Dashboard from './Dashboard';
import Polls from './Polls';
import Poll from './Poll';
import CreatePoll from './CreatePoll';
import Vote from './Vote';

function App() {

  var [isMobile,setIsMobile]=useState(window.innerHeight>window.innerWidth);
  const [loginStatus,setLoginStatus]=useState('Log in');

    useEffect(()=>{

      console.log(document.cookie);
    
    const screenChange=()=>{
      setIsMobile(window.innerHeight>window.innerWidth);
    }

    window.addEventListener('resize',screenChange);

    return ()=>{window.removeEventListener('resize',screenChange);};
  },[]);

  const [menuItemsVisiblity,setMenuItemsVisibility]=useState('displayNone');
  const [activeItem,setActiveItem]=useState(window.location.pathname.substring(1)===''?'login':window.location.pathname.substring(1));

  return (
    <div className="backgroundBlue0 colorWhite overflowXHidden">
            <div className='displayFlex alignItemsCenter backgroundWhite'>
        <div className={isMobile?'W90vw displayFlex alignItemsCenter':'displayFlex alignItemsCenter'}>
        <Link onClick={()=>setActiveItem('login')} className='navMenuBranding textDecorationNone backgroundWhite displayFlex alignItemsCenter minimalPadding' to='/'><img className='logoSize' src='/favicon.png'></img><span className='colorWhite backgroundBlack fontWeightBold logoFontSize'>ALTER</span><span className='backgroundBlack fontWeightBold colorGreen0 logoFontSize'>UX</span></Link>
        {/* <img className='logoSize' src='/logo_1.png'></img> */}
        </div>
        {!isMobile && <NavigationBar activeItem={activeItem} setActiveItem={setActiveItem} loginStatus={loginStatus} setLoginStatus={setLoginStatus}/>}
        {isMobile && <NavigationMenu menuItemsVisiblity={menuItemsVisiblity} setMenuItemsVisibility={setMenuItemsVisibility}/>}
        </div>

        <NavigationMenuItems activeItem={activeItem} setActiveItem={setActiveItem} menuItemsVisiblity={menuItemsVisiblity} setMenuItemsVisibility={setMenuItemsVisibility} loginStatus={loginStatus} setLoginStatus={setLoginStatus}/>

        <Routes>
        <Route path='/' element={<Login setLoginStatus={setLoginStatus}/>}/>
        <Route path='/login' element={<Login setLoginStatus={setLoginStatus}/>}/>
          <Route path='/home' element={<Home isMobile={isMobile}/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          {/* <Route path='/learn' element={<Learn/>}/> */}
          {/* <Route path='/projects' element={<Projects/>}/> */}
          <Route path='/upload' element={<Upload/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/dashboard' element={<Dashboard loginStatus={loginStatus}/>}/>
          <Route path='/polls' element={<Polls setLoginStatus={setLoginStatus}/>}/>
          <Route path='/poll/:pollId' element={<Poll setLoginStatus={setLoginStatus}/>}/>
          <Route path='/createPoll' element={<CreatePoll/>}/>
          <Route path='/vote/:pollId' element={<Vote/>}/>
        </Routes>
    </div>
  );
}

export default App;


function Home({isMobile}){

  const carouselElementRef=useRef(null);
  const carouselIndicatorDotsRef=useRef(null);

  const [backgroundColorOnHover,setBackgroundColorOnHover]=useState('backgroundWhite');

  const sectorRelating=useRef(null);

  const sectorRelatingArray=['INSIGHTS','BUSINESS','CAUSE','REACH'];
  const sectorCurrentPosition=useRef(0);
  const sectorMaxCount=4;

  useEffect(()=>{
    
    // const toggleFunction=()=>{setSectorRelating(sectorRelating==='business'?'cause':'business');};

    const toggleFunction=()=>{

      sectorCurrentPosition.current++;
      
      sectorCurrentPosition.current=(sectorCurrentPosition.current===sectorMaxCount?0:sectorCurrentPosition.current);

      sectorRelating.current.textContent=sectorRelatingArray[sectorCurrentPosition.current];

      sectorRelating.current.classList.toggle('fadeInOut0');
      sectorRelating.current.classList.toggle('fadeInOut1');

    };
    
   const timedInterval=setInterval(toggleFunction,4000);

    return ()=>clearInterval(timedInterval);
  },[sectorRelating]);

  
  var [currentSlide,setCurrentSlide]=useState(0);
  const slides=[<Slide0/>,<Slide1/>,<Slide2/>,<Slide3/>,<Slide4/>,<Slide5/>];

  return(
    <>

    <div className='displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>



    <div className={'W100vw H50vh displayFlex justifyContentCenter alignItemsCenter'}>

        <p className='W45vw textAlignRight motoFontSize colorGreen0'><b>EXPAND&nbsp;</b></p>
        <p className={'W60vw textAlignLeft motoFontSize'}><b>YOUR <span className='fadeInOut0' ref={sectorRelating}>INSIGHTS</span></b></p>
    

  </div>

<Carousel carouselElementRef={carouselElementRef} carouselIndicatorDotsRef={carouselIndicatorDotsRef} isMobile={isMobile} slides={slides} maxSlides={5} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} backgroundColorOnHover={backgroundColorOnHover} setBackgroundColorOnHover={setBackgroundColorOnHover}/>
<CarouselIndicatorDots carouselIndicatorDotsRef={carouselIndicatorDotsRef} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} circleCount={6} backgroundColorOnHover={backgroundColorOnHover}/>

<div className={'W100vw minHeight50vh backgroundBlack displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<p className='fontWeightBold readableSymbolSize minimalPadding textAlignCenter minimalMargin'>Timeless Surveys with Real-Time Insights</p>
<p className='fontWeightBold readableFontSize minimalPadding minimalMargin colorBlack backgroundWhite minimalBorderRadius'>
For centuries, surveys have been essential for gathering opinions.
Today, we honor that tradition by integrating real-time data processing.
Our platform allows you to conduct classic surveys effortlessly, delivering immediate results that engage participants.
Experience the rich history of feedback collection, enhanced with modern technology for instant insights.
</p>
</div>

{/* <div className={'W100vw H50vh backgroundWhite displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<h1 className='colorBlack'>3D Interactive Designs</h1>
  <div className={'W50vw displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'}>
<ModelViewer/>
</div>
</div> */}

</div>


</>
  );
}


function Contact(){
  return(<div className='H100vh W100vw displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>
    {/* <a className='colorWhite textDecorationUnderlineColorGreen0' href='mailto:shivarajkumar.moria@gmail.com'><h1>Email</h1></a> */}
    <a className='colorWhite textDecorationUnderlineColorGreen0' href='https://www.linkedin.com/in/shivarajkumar-m'><h1>LinkedIn</h1></a>
  </div>);
}

function About(){
  return(<div className='H100vh displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter minimalPadding'>

    <div className='textAlignCenter minimalPadding backgroundBlack borderRadius1vw'>
    <p className='readableFontSize'>Founded by a <span className='colorEffect fontWeightBold'>Software Engineer</span></p>
    <p className='readableFontSize minimalPadding'><Link className='colorWhite textDecorationUnderlineColorGreen0' to='/contact'>Connect</Link> with us, for custom software.</p>
    <p className='readableFontSize colorBlue0'><b>AVAILABLE FOR HIRE</b></p>
    </div>

    </div>);
}

// function Learn(){
//   return(
//   <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
//     <div>
//   <h1>Learn</h1>
//   <p>Add a page that teaches</p>
//   </div>
//   </div>
//   );
// }

// function Projects(){

//   return(
//   <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
//     <div className='textAlignCenter'>
//   <h1>Cipher</h1>
//   <p className='readableFontSize minimalPadding'>A project aimed at securing private data</p>
//   <a href="https://alterux.in/cipher">Click here to visit</a>
//   </div>
//   </div>
//   );
// }

function Upload(){
  
  return(
    <div className='H100vh W100vw displayFlex justifyContentCenter alignItemsCenter'>
    <div>
      <form method='post' action='/uploadfile' enctype='multipart/form-data'>
      <input type='file' name='fileName'></input>
      <input type='password' name='passwordName'></input>
      <input type='submit' value='Upload'></input>
      </form>
  </div>
  </div>
  );

}


function NavigationMenu({menuItemsVisiblity,setMenuItemsVisibility}){

  function expandMenu(){
    menuItemsVisiblity==='displayNone'?setMenuItemsVisibility('displayBlock'):setMenuItemsVisibility('displayNone');
  }

  return (
    <div className='nonPrintable'>
        <button onClick={expandMenu} className='menuButton'><img className='menuImage' src='https://alterux.in/menuIcon.svg' alt='Menu'></img></button>
        </div>
  );
}


// Mobile Navigation
function NavigationMenuItems({activeItem,setActiveItem,menuItemsVisiblity,setMenuItemsVisibility,loginStatus,setLoginStatus}){
  const navigate=useNavigate();

  function logInOrOut(activeItemName){

    setActiveItem(activeItemName);
    menuItemsVisiblity==='displayNone'?setMenuItemsVisibility('displayBlock'):setMenuItemsVisibility('displayNone');

    if(loginStatus==='Log in'){
      navigate('/login');
    }else{

      let urlToConnect='https://alterux.in/logoutSubmission';
      // let urlToConnect='http://localhost:8080/logoutSubmission';
  
      fetch(urlToConnect,{method:'PATCH',credentials:'include'}).then((response)=>response.json()).then(responseJSON=>{
          if(responseJSON.statusMessage==='SUCCESSFUL'){
            setLoginStatus('Log in');
            navigate('/login');
          }
      });

    }
    
  }

  function menuItemClicked(activeItemName){
    setActiveItem(activeItemName);
    menuItemsVisiblity==='displayNone'?setMenuItemsVisibility('displayBlock'):setMenuItemsVisibility('displayNone');
    
  }

  return (
  <nav className={menuItemsVisiblity+' backgroundColorWhite80Per displayFlex flexDirectionColumn textAlignCenter borderRadius1vw'}>
    <Link onClick={()=>menuItemClicked('home')} className={(activeItem==='home'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/home'>Home</Link>
    <Link onClick={()=>menuItemClicked('dashboard')} className={(activeItem==='dashboard'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/dashboard'>Dashboard</Link>
    <Link onClick={()=>menuItemClicked('about')} className={(activeItem==='about'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/about'>About</Link>
    <Link onClick={()=>menuItemClicked('contact')} className={(activeItem==='contact'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/contact'>Contact</Link>
    <button onClick={()=>logInOrOut('login')} className={((loginStatus==='Log in' && activeItem!=='login')?'colorGreen0 ':'colorBlack50Per ')+menuItemsVisiblity+' textDecorationNone minimalPadding fontWeightBold borderStyleNone backgroundTransparent'}>{loginStatus}</button>
    {/* <Link onClick={()=>menuItemClicked('learn')} className={(activeItem==='learn'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/learn'>Learn</Link> */}
    {/* <Link onClick={()=>menuItemClicked('projects')} className={(activeItem==='projects'?'colorBlack50Per ':'colorBlack ')+menuItemsVisiblity+' textDecorationNone minimalPadding'} to='/projects'>Projects</Link> */}
  </nav>);
}


// Desktop Navigation
function NavigationBar({activeItem,setActiveItem,loginStatus,setLoginStatus}){
  const navigate=useNavigate();

  function logInOrOut(activeItemName){

    setActiveItem(activeItemName);

    if(loginStatus==='Log in'){
      navigate('/login');
    }else{

      let urlToConnect='https://alterux.in/logoutSubmission';
      // let urlToConnect='http://localhost:8080/logoutSubmission';
  
      fetch(urlToConnect,{method:'PATCH',credentials:'include'}).then((response)=>response.json()).then(responseJSON=>{
          if(responseJSON.statusMessage==='SUCCESSFUL'){
            setLoginStatus('Log in');
            navigate('/login');
          }
      });

    }
    
  }

  function menuItemClicked(activeItemName){
    setActiveItem(activeItemName);
  }

return (
  <nav className='nonPrintable displayFlex alignItemsCenter'>
    <Link onClick={()=>menuItemClicked('home')} className={(activeItem==='home'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/home'>Home</Link>
    <Link onClick={()=>menuItemClicked('dashboard')} className={(activeItem==='dashboard'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/dashboard'>Dashboard</Link>
    <Link onClick={()=>menuItemClicked('about')} className={(activeItem==='about'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/about'>About</Link>
    <Link onClick={()=>menuItemClicked('contact')} className={(activeItem==='contact'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/contact'>Contact</Link>
    <button onClick={()=>logInOrOut('login')} className={((loginStatus==='Log in' && activeItem!=='login')?'colorGreen0 ':'colorBlack50Per ')+'textDecorationNone minimalPadding positionAbsolute right0 fontWeightBold borderStyleNone backgroundTransparent'}>{loginStatus}</button>
    {/* <Link onClick={()=>menuItemClicked('learn')} className={(activeItem==='learn'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/learn'>Learn</Link> */}
    {/* <Link onClick={()=>menuItemClicked('projects')} className={(activeItem==='projects'?'colorBlack50Per ':'colorBlack ')+'textDecorationNone minimalPadding'} to='/projects'>Projects</Link> */}
  </nav>
);
}






function Slide0(){

  return (<div className={'displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter userSelectNone'}>
    <p className='colorGray motoFontSize fontWeightBold'>How easy is it to create a poll<span className='colorGreen0 minimalPadding'>?...</span></p>
  </div>);
}

function Slide1(){

  return( <div className={'displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter'}> 

    <input className={'backgroundWhite placeHolderOpacity minimalPadding minimalPadding minimalMargin outlineNone borderStyleSolid borderColorBlack minimalBorderRadiusButton minimalBorderWidth'} type='text' minLength={8} placeholder='Enter your email address' disabled></input>

        <div className={`displayFlex alignItemsCenter backgroundWhite minimalPadding minimalMargin borderStyleSolid borderColorBlack minimalBorderRadiusButton minimalBorderWidth`} >
            <input className={'padding0 placeHolderOpacity borderStyleNone'} type='password' minLength={8} maxLength={16} placeholder='Enter your password' disabled></input>
            <button className={'padding0 backgroundTransparent borderStyleNone'}></button>
            </div>

    <input className={'backgroundWhite placeHolderOpacity minimalPadding minimalMargin outlineNone borderStyleSolid borderColorBlack minimalBorderRadiusButton minimalBorderWidth'} type='password'minLength={8} maxLength={16} placeholder='Confirm your password' disabled></input>
    <button className='minimalPadding minimalMargin fontWeightBold colorBlack backgroundGreen0 borderStyleSolid minimalBorderRadiusButton'>Sign Up</button>
    
    </div>
 )
}

function Slide2(){

  return (<div className={`displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter`}> 
            
    <input className={`backgroundWhite signUpInput placeHolderOpacity minimalPaddingXTopXBottom minimalMargin outlineNone borderStyleSolid borderColorBlack minimalBorderRadius minimalBorderWidth`} type='text' minLength={8} placeholder='Enter your email address' disabled></input>
        <div className={`signUpInputHolder displayFlex alignItemsCenter backgroundWhite minimalPaddingXTopXBottom minimalMargin borderStyleSolid borderColorBlack minimalBorderRadius minimalBorderWidth`} >
        <input className={`signUpInputPassword placeHolderOpacity borderStyleNone outlineNone minimalBorderRadius minimalPaddingXTopXBottom`} type='password' minLength={8} maxLength={16} placeholder='Enter your password' disabled></input>
        <button className={`padding0 backgroundTransparent borderStyleNone`}><img className={`H5vh`} src='/visibility_off.svg'></img></button>
        </div>
        <button className={`signUpButton minimalPadding minimalMargin fontWeightBold colorBlack backgroundGreen0 borderStyleSolid minimalBorderRadiusButton`}>Log in</button>
        </div>
);
}

function Slide3(){

  return (<div className={'displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter userSelectNone'}>
    <p className={`W80vw textAlignCenter readableSymbolSize fontWeightBold backgroundBlack borderRadius1vw minimalPadding minimalMargin colorWhite`}><span className='padding0 readableSymbolSize colorGreen0 minimalPadding'>+ </span>CREATE A POLL</p>
  </div>);
}

function Slide4(){

  return (<div className={'displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter userSelectNone'}>
    <p className={`W80vw textAlignCenter readableSymbolSize fontWeightBold backgroundBlack borderRadius1vw minimalPadding minimalMargin colorWhite`}>TRACK YOUR POLLS</p>
  </div>);
}

function Slide5(){

  return (<div className={'displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter userSelectNone'}>
    <p className='colorGray motoFontSize fontWeightBold'><span className='colorGreen0'>...</span>That easy<span className='colorGreen0 minimalPadding'>!</span></p>
  </div>);
}

function Slide6(){

  return (<div className='minimalPaddingXTop displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter'>
    <div className='minimalPaddingXTop displayFlex flexDirectionColumn alignItemsCenter borderRadius1vw backgroundWhite borderStyleSolid borderColorBlack'>
    <h1 className='W100Per textAlignCenter colorBlack backgroundGreen0 minimalPadding marginTop0 borderRadius1vw'>What we do?</h1>
    <div className='displayFlex alignItemsCenter'>
      <ul className='colorBlack fontWeightBold readableFontSize margin0 listStyleSquare'>
      <li className='readableFontSize minimalPaddingXLeftXRight'>We specialize in designing, building, and maintaining custom web applications.</li>
        <li className='readableFontSize minimalPaddingXLeftXRight'>Businesses can choose a domain name that aligns with their brand.</li>
        <li className='readableFontSize minimalPaddingXLeftXRight'><Link className='colorBlack' to='/contact'>Connect</Link> with us for custom web applications tailored to your business needs.</li>
      </ul>
      </div>
      </div>
      </div>);
}



function Carousel({carouselElementRef,carouselIndicatorDotsRef,isMobile,slides,maxSlides,currentSlide,setCurrentSlide,backgroundColorOnHover,setBackgroundColorOnHover}){

  const currentSlideRef=useRef(0);

  function forward(){
    if(currentSlide===maxSlides)setCurrentSlide(0);else setCurrentSlide(currentSlide+=1);
  }

  function backward(){
    if(currentSlide===0)setCurrentSlide(maxSlides);else setCurrentSlide(currentSlide-=1);
  }

  var pointerStart;
  var pointerEnd;
  let pointerTracking=true;
  const pointerDown0=(event)=>{
    pointerTracking=true;
    pointerStart=event.clientX;
  };

  const pointerMove0=(event)=>{
    if(pointerTracking)currentSlideRef.current.style.transform=`translate(${-(pointerStart-event.clientX)}px, 0)`;
  };

  const pointerUp0=(event)=>{
    pointerTracking=false;
    pointerEnd=event.clientX;
    pointerEnd!==0&&swipeHandling();
  };

  function swipeHandling(){
    pointerStart>pointerEnd&&(Math.abs(pointerStart-pointerEnd)>(isMobile?80:220)) && forward();
    pointerStart<pointerEnd&&(Math.abs(pointerStart-pointerEnd)>(isMobile?80:220)) && backward();
    currentSlideRef.current.style.transform='translate(0, 0)';
  }

  const pointerLeave0=()=>{
    pointerTracking=false;
    currentSlideRef.current.style.transform='translate(0, 0)';
  };
  
  // const pointerEnter0=()=>{
  //   carouselElementRef.current.classList.add('backgroundColorFadeIn'); 
  //   carouselIndicatorDotsRef.current.classList.add('backgroundColorFadeIn'); 
  //   document.documentElement.style.setProperty('--backgroundColorInput',`rgb(245, 222, 179)`); 
  //   setBackgroundColorOnHover('backgroundColorFadeIn');
  // };

  // const mouseLeave0=()=>{
  //   carouselElementRef.current.classList.remove('backgroundColorFadeIn'); 
  //   carouselIndicatorDotsRef.current.classList.remove('backgroundColorFadeIn');
  // };

  // onPointerEnter={pointerEnter0} onMouseLeave={mouseLeave0} 

  return (
      <div ref={carouselElementRef} onPointerLeave={pointerLeave0} onPointerDown={pointerDown0} onPointerUp={pointerUp0} onPointerMove={pointerMove0} className={`W100vw H50vh displayFlex flexDirectionColumn justifyContentCenter alignItemsCenter minimalPadding userSelectNone touchActionPanY backgroundWhite ${backgroundColorOnHover}`}>
      
      <div ref={currentSlideRef} className='W100vw displayFlex justifyContentCenter alignItemsCenter minimalPadding'>
  {slides[currentSlide]}
        </div>

        </div>);
}


function CarouselIndicatorDots({carouselIndicatorDotsRef,currentSlide,setCurrentSlide,circleCount,backgroundColorOnHover}){

  let createCarouselIndicatorDots=[];

  function circleButtonClicked(circleButtonNo){
    setCurrentSlide(circleButtonNo);
  }

  for(let i=0;i<circleCount;i++){createCarouselIndicatorDots.push(<button key={i} onClick={()=>circleButtonClicked(i)} className={'circleButton minimalMargin '+(currentSlide===i?'backgroundBlack':'backgroundWhite')+' borderStyleSolid'}></button>)}

  return(<div ref={carouselIndicatorDotsRef} className={`W100vw displayFlex justifyContentCenter alignItemsCenter backgroundWhite ${backgroundColorOnHover}`}>
    {createCarouselIndicatorDots}
    </div>);
}
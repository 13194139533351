import {Link} from 'react-router-dom';
import {useEffect,useState} from 'react';

function Polls({setLoginStatus}){
    const [render,setRender]=useState(false);
    const [pollsStatus,setPollsStatus]=useState('');
    const [linkAndContent,setLinkAndContent]=useState(<></>);
    const [deleteButton,setDeleteButton]=useState(<></>);

    //FETCH ALL POLL QUESTIONS AND POLL IDS USING USERID(USING SESSIONID), DISPLAY ALL QUESTIONS IN SEQUENCE
    //WHEN USER CLICKS ON A PARTICULAR POLL ID MAPPED QUESTION, MOVE THE USER TO 

    useEffect(()=>{
    let urlToConnect='https://alterux.in/activePolls';
    // let urlToConnect='http://localhost:8080/activePolls';
    
    fetch(urlToConnect,{method:'GET',credentials:'include'})
    .then(response=>response.json()).then(responseJSON=>{
     
     if(responseJSON.pollStatus==='SUCCESSFUL'){
        setLoginStatus('Log out');
        setPollsStatus(responseJSON.pollQuestion);
        setLinkAndContent((<Link className='readableSymbolSize colorBlack minimalMargin textDecorationNone backgroundWhite minimalPadding minimalBorderRadiusButton borderStyleSolid' to={`/poll/${responseJSON.pollId}`}>view</Link>));
        setDeleteButton((<button className='readableSymbolSize colorBlack minimalMargin textDecorationNone backgroundWhite minimalPadding minimalBorderRadiusButton borderStyleSolid' onClick={()=>deletePoll(responseJSON.pollId)}>delete</button>));
    }else if(responseJSON.pollStatus==='NO ACTIVE POLLS'){
        setPollsStatus('You have no active polls. ');
        setLinkAndContent((<Link className='readableSymbolSize colorBlack minimalMargin textDecorationNone backgroundWhite minimalPadding minimalBorderRadiusButton borderStyleSolid' to='/createPoll'>Create poll</Link>));
        setDeleteButton(<></>);
    }else if(responseJSON.pollStatus==='LOG IN'){
        setLoginStatus('Log in');
        setPollsStatus('Session expired.');
        setLinkAndContent((<Link className='readableSymbolSize colorBlack minimalMargin textDecorationNone backgroundWhite minimalPadding minimalBorderRadiusButton borderStyleSolid' to='/login'>log in</Link>));
    }else {
        setLoginStatus('Log in');
        setPollsStatus('Please try after sometime. ');
        setLinkAndContent((<Link className='colorGreen0' to='/login'>Log in</Link>));
    }

    });

    },[render]);

    function deletePoll(pollId){

            let urlToConnect=`https://alterux.in/deletePoll/${pollId}`;
    // let urlToConnect=`http://localhost:8080/deletePoll/${pollId}`;

        fetch(urlToConnect,{method:'DELETE', credentials:'include', headers:{'Content-Type':'application/json'}})
        .then(response=>{if(response.ok){
            setRender(true);
        }else{
            console.log(response.status);
        }});

    }

    return (<div className='displayFlex flexDirectionColumn'>
        
        <p className='readableSymbolSize fontWeightBold alignTextCenter textAlignCenter colorBlack backgroundGreen0 margin0 minimalPadding'>YOUR ACTIVE POLLS</p>
        
        <div className='minHeight100vh displayFlex alignItemsCenter justifyContentCenter'>
        <p className='readableSymbolSize fontWeightBold textAlignCenter minimalPadding minimalMargin minimalBorderRadiusButton borderStyleSolid backgroundBlack'>{pollsStatus}</p>
        {linkAndContent}
        {deleteButton}
        </div>

            </div>
            );
}
export default Polls;
import {useRef,useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

function CreatePoll(){

    const navigate=useNavigate();

    const pollQuestionRef=useRef(null);
    const [pollStatus,setPollStatus]=useState('');

    const [options,setOptions]=useState([{optionNumber:1,optionValue:''},{optionNumber:2,optionValue:''}]);

    


    function addOption(){
        if(options.length<5)setOptions([...options,{optionNumber:options.length+1,optionValue:''}]);
    }


    function createPoll(){

        let minLength=true;
        let checkOption=0;
        for(let i=0;i<options.length;i++){

            if(options[i].optionValue.length===0){
                minLength=false;
                checkOption=i;
                break;
            }

        }

if(!minLength){
    
    setPollStatus('Empty option/question! Look at OPTION '+(checkOption+1)+'.');

}else{
        const pollQuestion=pollQuestionRef.current.value;

           let optionCount=options.length;

       let optionValues='';
       options.map(optionObject=>optionValues+=(optionObject.optionValue+'::'));

       console.log(optionCount+" "+optionValues);

       let urlToConnect='https://alterux.in/createPollSubmission';
    //    let urlToConnect='http://localhost:8080/createPollSubmission';

       fetch(urlToConnect,{method:'POST', credentials:'include', headers:{'Content-Type':'application/json'},body:JSON.stringify({'pollQuestion':pollQuestion,'optionCount':optionCount,'optionValues':optionValues})})
       .then(response=>response.json()).then(responseJSON=>{
        
        if(responseJSON.pollStatus==='SUCCESSFUL'){const pollId=responseJSON.pollId; navigate(`/poll/${pollId}`);}
        else if(responseJSON.pollStatus==='ACTIVE POLL')setPollStatus('You have a active poll.');
        else if(responseJSON.pollStatus==='LOG IN')setPollStatus(<>Session expired. Please <Link className='colorGreen0' to='/login'>log in</Link></>);
        else setPollStatus('Please try after sometime.');
       });
}
    }

    function updateOptionValue(updateNumber,updateValue){
        let updatedOptions=[...options];
        updatedOptions.map(optionObject=>{if(optionObject.optionNumber===updateNumber)optionObject.optionValue=updateValue;});
        setOptions(updatedOptions);
    }

    function deleteOption(updatedOptionIndex){
        
           if(options.length>2){
            options.splice(updatedOptionIndex,1);
            let updatedOptions=[...options];
            updatedOptions.map((optionObject,index)=>{if(index>=updatedOptionIndex){optionObject.optionNumber=index+1;}} );
            setOptions(updatedOptions);
        }

    }


    return (
    <div className='W100vw minHeight100vh displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter backgroundBlue0'>
        
    <input ref={pollQuestionRef} className={'signUpInput placeHolderOpacity minimalMargin outlineNone borderStyleSolid borderColorGreen0 minimalBorderRadius'} type='text' minLength={8} maxLength={250} placeholder='ENTER QUESTION'></input>


    {options.map(((optionObject,index)=>(
        
        <div className='signUpInputHolder displayFlex alignItemsCenter backgroundWhite minimalMargin minimalBorderRadius borderStyleSolid borderColorBlack'>
        <input className={'signUpInputPassword placeHolderOpacity borderStyleNone outlineNone minimalBorderRadius minimalPaddingXTopXBottom'} type='text' minLength={8} maxLength={250} placeholder={`ENTER OPTION ${optionObject.optionNumber}`}
        onChange={(e)=>updateOptionValue(optionObject.optionNumber,e.target.value)} value={optionObject.optionValue}
        ></input>
        <button className='padding0 backgroundTransparent borderStyleNone' onClick={()=>deleteOption(index)}>—</button>
        
        </div>

    )))
    }

    <div className='minimalMargin displayFlex flexDirectionColumn'>
    <button className='signUpButton minimalPadding minimalMargin fontWeightBold borderStyleSolid borderRadius1vw colorBlack backgroundGreen0 minimalBorderRadiusButton' onClick={addOption}>ADD OPTION</button>

    <button className='signUpButton minimalPadding minimalMargin fontWeightBold borderStyleSolid borderRadius1vw colorBlack backgroundGreen0 minimalBorderRadiusButton' onClick={createPoll}>CREATE</button>

    <Link className='readableSymbolSize fontWeightBold backgroundBlack borderRadius1vw minimalPadding minimalMargin textDecorationNone colorWhite textAlignCenter' to='/polls'>YOUR ACTIVE POLLS</Link>
    </div>

    <p className='readableFontSize textAlignCenter fontWeightBold'>{pollStatus}</p>
    </div>);
}
export default CreatePoll;
import QRCode from 'react-qr-code';
import { Link,useParams } from 'react-router-dom';
import {useEffect,useRef,useState} from 'react';

function Poll({setLoginStatus}){

    const [render,setRender]=useState(false);
    const {pollId}=useParams();
    const [linkAndContent,setLinkAndContent]=useState((<></>));
    const [pollStatus,setPollStatus]=useState('');
    const voteLink=useRef(null);
    const [totalVoteCount,setTotalVoteCount]=useState(0);
    const [selectedOptionsAndCount,setSelectedOptionsAndCount]=useState({});
    const [optionValues, setOptionValues]=useState('');
    const [H100vhBackground,setH100vhBackground]=useState('');
    // const [borderStyle,setBorderStyle]=useState('borderStyleSolid');
    const [disableRefreshButton,setDisableRefreshButton]=useState(false);
    const [pollVisibility,setPollVisibility]=useState('displayNone');
    const [shareableVisibility,setShareableVisibility]=useState('displayNone');
    let stageHolder=0;
    let conicEnd=0;
    let finalConicGradient='';
    let letOptionColor='';
    let viewCollectionArray=[<></>];
    const [viewCollection,setViewCollection]=useState([<></>]);

    useEffect(()=>{
    let urlToConnect=`https://alterux.in/pollDetails/${pollId}`;
    // let urlToConnect=`http://localhost:8080/pollDetails/${pollId}`;
    fetch(urlToConnect,{method:'GET',credentials:'include'})
       .then(response=>response.json()).then(responseJSON=>{
        
        if(responseJSON.statusMessage==='SUCCESSFUL'){

            setLoginStatus('Log out');

            if(responseJSON.voteStatus==='VOTES'){

            setShareableVisibility('displayBlock');
            setPollVisibility('displayBlock'); 
            setPollStatus(responseJSON.pollQuestion); 
            setLinkAndContent((<></>));
            setTotalVoteCount(responseJSON.totalVoteCount);
            setOptionValues(responseJSON.optionValues);
            setSelectedOptionsAndCount(responseJSON.selectedOptionsAndCount);
         
            }else{
                setShareableVisibility('displayBlock');
                setPollVisibility('displayBlock'); 
                setPollStatus(responseJSON.pollQuestion); 
                setLinkAndContent((<></>));
                setTotalVoteCount(0);
                setOptionValues(responseJSON.optionValues);
                setSelectedOptionsAndCount(responseJSON.selectedOptionsAndCount);
                // setPollVisibility('displayNone'); setPollStatus('No one has voted yet.'); setLinkAndContent((<></>));

            }
            
        }
        // else if(responseJSON.statusMessage==='LOG IN'){setPollVisibility('displayBlock'); setPollStatus("Best country in the world?"); setLinkAndContent((<></>));}
        else if(responseJSON.statusMessage==='LOG IN'){

            setLoginStatus('Log in');

            setH100vhBackground('H100vh');
            setShareableVisibility('displayNone');
            setPollVisibility('displayNone');
            setPollStatus('Session expired. Please '); 
            setLinkAndContent((<Link className='colorGreen0' to='/login'>log in</Link>));
        }
        else {

            setLoginStatus('Log in');

            setH100vhBackground('H100vh');
            setShareableVisibility('displayNone');
            setPollVisibility('displayNone');
            setPollStatus('Please try after sometime. ');
            setLinkAndContent((<Link className='colorGreen0' to='/login'>log in</Link>));}
       });
},[render]);


function copyTextOnParagraphClick(){
    let text=voteLink.current.textContent;
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied!');
    })
    }


    function reRender(){

        setDisableRefreshButton(true);
        setRender(currentValue=>!currentValue);
        setTimeout(()=>{setDisableRefreshButton(false);},2000);

    }


    function printShareable(){
        setPollVisibility('displayNone'); 
        setTimeout(()=>{
            window.print();

        setPollVisibility('displayBlock'); 
        },1000);
    }


    useEffect(()=>{

            optionValues.split('::').map((value,index)=>{
                
                if((selectedOptionsAndCount)&&(index in selectedOptionsAndCount)){

                conicEnd+=(selectedOptionsAndCount[index]/totalVoteCount)*100;

                letOptionColor=`rgb(${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)},${Math.floor(Math.random()*256)})`;

                finalConicGradient+=`${letOptionColor} ${stageHolder}% ${conicEnd}%,`;

                stageHolder=conicEnd;

                console.log(value);
                
                }

                if(value)viewCollectionArray=[...viewCollectionArray,<p key={index} style={{ color:(selectedOptionsAndCount)&&(index in selectedOptionsAndCount)?letOptionColor:'rgb(255,255,255)'}} className={`W80vw readableSymbolSize fontWeightBold textAlignCenter minimalBorderRadiusButton minimalPadding backgroundBlack ${pollVisibility}`}>{value}<span className={`colorGreen0 minimalPadding ${pollVisibility}`}>{((selectedOptionsAndCount)&&(index in selectedOptionsAndCount))?selectedOptionsAndCount[index]:0}</span></p>]

            }

            )
            
            console.log(viewCollectionArray)
            setViewCollection(viewCollectionArray);
            document.documentElement.style.setProperty('--conicGradientDynamic',`conic-gradient(${finalConicGradient.substring(0,finalConicGradient.length-1)})`);

    },[totalVoteCount,render])


    return (
<div className={`displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter ${H100vhBackground}`}>


<div className={`displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter minimalMargin backgroundBlack borderStyleSolid minimalBorderWidth minimalBorderRadiusButton borderColorWhite page minimalPadding`}>
        
        <p className='motoFontSize fontWeightBold minimalPadding minimalMargin textAlignCenter'>{pollStatus}{linkAndContent}</p>

        <div className={`backgroundWhite minimalPadding minimalMargin borderStyleSolid borderColorGreen0 borderWidth1vw minimalBorderRadiusButton ${shareableVisibility}`}>
    <QRCode title="title"
        value={`https://alterux.in/vote/${pollId}`}
        bgColor="#FFFFFF"
        fgcolor="#000000"
        level="H"
        size='25vw'/>
        </div>
        <p className={`readableSymbolSize fontWeightBold minimalPadding minimalMargin textAlignCenter ${shareableVisibility}`}>SCAN TO ANSWER</p>
        

        <div className={`displayFlex justifyContentCenter minimalMargin`}>
        <p ref={voteLink} className={`readableFontSize fontWeightBold minimalMargin textAlignCenter ${shareableVisibility}`}>https://alterux.in/vote/{pollId}</p>
        <button className={`padding0 backgroundTransparent borderStyleNone ${shareableVisibility}`} onClick={copyTextOnParagraphClick}><img className={`readableFontSize minimalIconSize`} src='/content_copy.svg'></img></button>
        </div>
        
        <button className={`fontWeightBold mminimalMarginXTop minimalPadding borderStyleSolid borderColorBlack minimalBorderRadiusButton backgroundGreen0 ${pollVisibility}`} onClick={printShareable}>Print</button>

        </div>


{
        
        (totalVoteCount!==0)?<div className={`displayFlex flexDirectionColumn alignItemsCenter justifyContentCenter minimalPadding minimalMargin minimalBorderRadiusButton ${pollVisibility}`}>
        
        {/* Updating render to execute useEffect once more - from here */}
        <button className={`minimalPadding minimalMargin1 backgroundTransparent borderStyleNone ${pollVisibility}`} onClick={reRender} disabled={disableRefreshButton}><img className={`readableFontSize minimalIconSize`} src='/sync.svg'></img></button>
        {/* Updating render to execute useEffect once more - to here */}

        <p className={`readableSymbolSize fontWeightBold minimalMargin textAlignCenter ${pollVisibility}`}>{pollStatus}</p>



        <div className="borderStyleSolid borderColorWhite minimalPadding minimalMargin1 circular-chart"></div>


<div className='borderStyleSolid borderColorBlack minimalBorderRadiusButton backgroundWhite minimalPadding minimalMargin1'>
{
viewCollection
}
</div>

<p className={`readableSymbolSize fontWeightBold minimalPadding minimalMargin textAlignCenter backgroundBlack minimalBorderRadiusButton ${pollVisibility}`}>Total vote count is: <span className='colorGreen0 minimalPadding minimalMargin'>{totalVoteCount}</span></p>

        </div>:<p className={`readableSymbolSize fontWeightBold minimalMargin textAlignCenter ${pollVisibility}`}>No one voted yet</p>
}
        </div>

    );
}
export default Poll;